import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    plan: {
        name: 'Plan',
        required: true,
        custom_message: 'Please choose mobile service plan',
    },

    MSISDN: {
        name: 'Number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                                // после всех предпроверок и замен - value должно соответсвовать format
    },

    MSISDNProvider: {
        name: 'Current/Losing Service Provider',
        required: true,
    },

    MSISDNNumber: {
        name: 'Available Number',
        required: true,
    },

    IMSI: {
        name: 'IMSI',
        required: true,
        format: /^\d{15}$/,
    },

    IMSISimCardID: {
        name: 'SIM Card ID',
        required: true,
        format: /^(\d{16}|\d{19})$/,
    },

    IMSILocation: {
        name: 'Ship to Address',
        required: true,
    },
})