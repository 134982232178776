<template>
    <div class="stage-service">
        <app-loader v-if="loading" />
        <app-error v-model="errors.show" :message="errors.message" />

        <div>
            <app-dropdown-select class="wideselection"
                v-model="service.plan"
                :options="plans"

                label="Plan *"

                key-value="UUID"
                key-title="CompositeNameDescription"

                :error="errors.fields.plan"

                @change="errors.fields.plan = null"
            ></app-dropdown-select><button v-if="!plans || (Object.keys(plans).length == 0)" class="btn btn-primary btn-small" @click="getMobileServicesPlans">Reload</button>
        </div>

        <div class="allocate">
            <h5>MSISDN / Phone Number</h5>

            <div class="allocate-options">
                <app-radio-buttons
                    v-model="service.MSISDN.allocate"
                    :items="MSISDN_options"

                    @change="onChangeMSISDNAllocate"
                ></app-radio-buttons>

                <div class="inputs">
                    <app-dropdown-select v-if="service.MSISDN.allocate == 'number'"
                        v-model="service.MSISDN.phone"
                        :options="MSISDN_avaliable_phones"

                        key-value="Number"
                        key-title="Title"

                        label="Available Numbers"

                        :error="errors.fields.MSISDNNumber"

                        @change="errors.fields.MSISDNNumber = null"
                    ></app-dropdown-select>

                    <template v-else>
                        <app-input
                            v-model="service.MSISDN.manual"

                            label="Manual Number *"

                            type="tel"
                            maxlength="24"

                            :error="errors.fields.MSISDN"
                            :disabled="service.MSISDN.allocate == 'random'"

                            @change="onChangeMSISDNManual"
                        ></app-input>

                        <app-dropdown-select v-show="service.MSISDN.allocate == 'existing'"
                            v-model="service.MSISDN.provider"
                            :options="MSISDN_providers"

                            key-value="VoiceSPID"
                            key-title="Name"

                            label="Current/Losing Service Provider"

                            :error="errors.fields.MSISDNProvider"

                            @change="errors.fields.MSISDNProvider = null"
                        ></app-dropdown-select>
                    </template>
                </div>
            </div>
        </div>



        <div class="allocate">
            <h5>SIM Card ID</h5>

            <div class="allocate-options">
                <app-radio-buttons
                    v-model="service.IMSI.allocate"
                    :items="SIM_options"

                    @change="onChangeIMSIAllocate"
                ></app-radio-buttons>

                <div class="inputs">
                    <app-input v-if="service.IMSI.allocate == 'existing'"
                        v-model="service.IMSI.sim_id"

                        label="SIM Card ID *"
                        type="tel"
                        placeholder="Please enter the 16 or 19 digit SIM Card ID"

                        :mask="simid_mask"

                        :error="errors.fields.IMSISimCardID"

                        @change="onSIMIDChange"
                    ></app-input>

                    <form @submit.prevent class="form-location" v-else>
                        <app-input-location-autocomplete v-if="search_by_locationsvc"
                            v-model="service.IMSI.location.address"

                            :debounce="250"
                            :with-manually="false"

                            label="Ship to Address"
                            :disabled="processing"

                            :error="Boolean(errors.fields.IMSILocation)"

                            @change="resetAvailable"
                            @change-place="onChangePlace"

                            ref="location"
                        ></app-input-location-autocomplete>

                        <app-input-google-autocomplete-manually v-else
                            v-model="location.address"

                            label="Ship to Address"
                            :disabled="processing"

                            :error="Boolean(errors.fields.IMSILocation)"

                            @change="resetAvailable"
                            @change-place="onChangePlace"
                            @change-address="checkAvailability"

                            ref="location"
                        ></app-input-google-autocomplete-manually>

                        <app-checkbox v-model="service.IMSI.shipping">Add as customer default shipping address</app-checkbox>
                    </form>
                </div>
            </div>
        </div>

        <!-- <div>
            <app-checkbox v-model="service.voicemail">Voicemail?</app-checkbox>
        </div> -->

        <div>
            <button class="btn btn-primary btn-small" :disabled="!is_plan_selected || !is_sim_card_id_validation_successful" @click="confirm">Confirm</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'

import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'
import appRadioButtons from '@/components/app-radio-buttons'
import appCheckbox from '@/components/app-checkbox'

import appInputGoogleAutocompleteManually from '@/components/app-input-google-autocomplete-manually'
import appInputLocationAutocomplete from '@/components/app-input-location-autocomplete'

import MobileServiceValidator from '@/validators/mobile-service-validator'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appError,
        appLoader,
        appInput,
        appDropdownSelect,
        appRadioButtons,
        appCheckbox,

        appInputGoogleAutocompleteManually,
        appInputLocationAutocomplete,
    },

    data() {
        return {
            service: {
                plan: null,
                MSISDN: {
                    allocate: 'number',
                    manual: '+642',
                    provider: null,
                    phone: null,
                },
                IMSI: {
                    allocate: 'existing',
                    manual: '',
                    sim_id: '',
                    location: {
                        address: '',
                        location: {},
                        prediction: {},

                        variants: {
                            list: null,
                            checked: null,
                        },

                        available: null,
                    },
                    shipping: true,
                },
                voicemail: false,
            },

            MSISDN_options: [
                { value: 'number',   label: 'New number'                         },
                { value: 'existing', label: 'Existing / Port in', disabled: true },
                { value: 'manual',   label: 'Manual'                             },
            ],

            MSISDN_providers: [
                { "VoiceSPID": "1", "Name": "Vodafone" },
                { "VoiceSPID": "2", "Name": "Spark" },
                { "VoiceSPID": "3", "Name": "Call Plus" },
                { "VoiceSPID": "4", "Name": "Orcon" },
                { "VoiceSPID": "5", "Name": "Actrix" },
                { "VoiceSPID": "6", "Name": "Airnet" },
                { "VoiceSPID": "7", "Name": "BayCity New Zealand" },
                { "VoiceSPID": "8", "Name": "Business Online Limited" },
                { "VoiceSPID": "9", "Name": "Cable &amp; Wireless" },
                { "VoiceSPID": "10", "Name": "Call South" },
                { "VoiceSPID": "11", "Name": "Cogent Communications Ltd" },
                { "VoiceSPID": "12", "Name": "Communitel Ltd" },
                { "VoiceSPID": "13", "Name": "Compass" },
                { "VoiceSPID": "14", "Name": "Deltacom" },
                { "VoiceSPID": "15", "Name": "DigiPlus" },
                { "VoiceSPID": "16", "Name": "Digital Island" },
                { "VoiceSPID": "17", "Name": "Ecosse Business Equipment Ltd" },
                { "VoiceSPID": "18", "Name": "Esurf Wireless" },
                { "VoiceSPID": "19", "Name": "Eziphone Limied" },
                { "VoiceSPID": "20", "Name": "Flip" },
                { "VoiceSPID": "21", "Name": "Fuel-Tel" },
                { "VoiceSPID": "22", "Name": "GoTalk Limited" },
                { "VoiceSPID": "23", "Name": "ICONZ" },
                { "VoiceSPID": "24", "Name": "Image Net" },
                { "VoiceSPID": "25", "Name": "InspireNet" },
                { "VoiceSPID": "26", "Name": "Internet Solutions Ltd" },
                { "VoiceSPID": "27", "Name": "Irongate New Zealand Ltd" },
                { "VoiceSPID": "28", "Name": "Kiwi Reconnect" },
                { "VoiceSPID": "29", "Name": "Kordia Limited" },
                { "VoiceSPID": "30", "Name": "M2 NZ Ltd" },
                { "VoiceSPID": "31", "Name": "Maxnet" },
                { "VoiceSPID": "32", "Name": "Megatel" },
                { "VoiceSPID": "33", "Name": "Mercury" },
                { "VoiceSPID": "34", "Name": "MyRepublic" },
                { "VoiceSPID": "35", "Name": "OneCard" },
                { "VoiceSPID": "36", "Name": "OneNetwork" },
                { "VoiceSPID": "37", "Name": "OneZone" },
                { "VoiceSPID": "38", "Name": "Planet Communications" },
                { "VoiceSPID": "39", "Name": "Quicker Net" },
                { "VoiceSPID": "40", "Name": "Reconx" },
                { "VoiceSPID": "41", "Name": "Securecom Hostings Ltd" },
                { "VoiceSPID": "42", "Name": "Simply Calling Ltd" },
                { "VoiceSPID": "43", "Name": "Smartel" },
                { "VoiceSPID": "44", "Name": "Snap" },
                { "VoiceSPID": "45", "Name": "Sprint" },
                { "VoiceSPID": "46", "Name": "State Services Commission" },
                { "VoiceSPID": "47", "Name": "Tasman Solutions" },
                { "VoiceSPID": "48", "Name": "TeamTalk Ltd" },
                { "VoiceSPID": "49", "Name": "telcoinabox" },
                { "VoiceSPID": "50", "Name": "TelstraClear Residential" },
                { "VoiceSPID": "51", "Name": "The Packing Shed" },
                { "VoiceSPID": "52", "Name": "TrustPower Limited" },
                { "VoiceSPID": "53", "Name": "UCS Limited" },
                { "VoiceSPID": "54", "Name": "Woosh" },
                { "VoiceSPID": "55", "Name": "World Exchange" },
                { "VoiceSPID": "56", "Name": "WorldNet" },
                { "VoiceSPID": "57", "Name": "Xtreme Networks" },
                { "VoiceSPID": "58", "Name": "Zintel" },
                { "VoiceSPID": "59", "Name": "5Star Net" }
            ],

            MSISDN_avaliable_phones: [
                { "Number": "02123456700", "Title": "(021) 123-456-700" },
                { "Number": "02123456701", "Title": "(021) 123-456-701" },
                { "Number": "02123456702", "Title": "(021) 123-456-702" },
                { "Number": "02123456703", "Title": "(021) 123-456-703" },
                { "Number": "02123456704", "Title": "(021) 123-456-704" },
                { "Number": "02123456705", "Title": "(021) 123-456-705" },
                { "Number": "02123456706", "Title": "(021) 123-456-706" },
                { "Number": "02123456707", "Title": "(021) 123-456-707" },
                { "Number": "02123456708", "Title": "(021) 123-456-708" },
                { "Number": "02123456709", "Title": "(021) 123-456-709" },
                { "Number": "02123456710", "Title": "(021) 123-456-710" },
                { "Number": "02123456711", "Title": "(021) 123-456-711" },
                { "Number": "02123456712", "Title": "(021) 123-456-712" },
                { "Number": "02123456713", "Title": "(021) 123-456-713" },
                { "Number": "02123456714", "Title": "(021) 123-456-714" },
            ],

            SIM_options: [
                { value: 'existing', label: 'Existing' },
            ],

            errors: {
                show: false,
                message: '',

                fields: {},
            },

            plans: {},

            is_sim_card_id_validation_successful: false,

            loading: false,
            processing: false,
        }
    },

    methods: {
        init() {
            this.getMobileServicesPlans()
            const shippingIsSupported = false
            if (shippingIsSupported) {
                this.SIM_options = [
                    { value: 'existing', label: 'Existing' },
                    { value: 'ship', label: 'Ship New SIM' },
                ]
            }
        },

        getMobileServicesPlans() {
            this.loading = true

            this.$store.dispatch('api_postpay/FindPostpayMobilePlansPaginated', { SPID: this.current_spid }).then(({ PostpayMobilePlans }) => {
 
                let plans = {}
                if (PostpayMobilePlans) {
                    for (let i = 0, len = PostpayMobilePlans.length; i < len; i++) {
                        plans[PostpayMobilePlans[i].UUID] = {
                            ...PostpayMobilePlans[i],

                            CompositeNameDescription: PostpayMobilePlans[i].Description
                                ? `${ PostpayMobilePlans[i].Name } / ${ PostpayMobilePlans[i].Description }`
                                : PostpayMobilePlans[i].Name,
                        }
                    }

                    this.$store.commit('saveMobileServicesPlans', PostpayMobilePlans)
                }
                this.plans = plans
                this.loading = false
            }).catch(error => {
                console.error(error)

                this.loading = false
            })
        },

        validateSIMCardID() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                SIMID: this.service.IMSI.sim_id,
            }

            this.$store.dispatch('api_simdb/FindSIMSPaginated', params)
                .then(({ SIMS }) => {
                    this.errors.show = false
                    this.errors.message = ''

                    if (SIMS.length == 0) {
                        this.is_sim_card_id_validation_successful = false

                        this.errors.show = true
                        this.errors.message = 'That SIMID is not available'
                    } else {
                        const sim = SIMS[0]

                        if (sim.Active) {
                            this.is_sim_card_id_validation_successful = false

                            this.errors.show = true
                            this.errors.message = `That SIMID is already in use by service ${ sim.ServiceInstanceUUID }`
                        } else {
                            this.is_sim_card_id_validation_successful = true
                        }
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.is_sim_card_id_validation_successful = false

                    this.loading = false
                })
        },

        validation() {
            this.errors.fields = {}

            const values = {
                plan: this.service.plan,
            }

            switch (this.service.MSISDN.allocate) {
                case 'number': {
                    values.MSISDNNumber = this.service.MSISDN.phone
                } break

                case 'existing': {
                    values.MSISDN = this.service.MSISDN.manual
                    values.MSISDNProvider = this.service.MSISDN.provider
                } break

                case 'manual': {
                    values.MSISDN = this.service.MSISDN.manual
                } break
            }

            switch (this.service.IMSI.allocate) {
                case 'existing': {
                    values.IMSISimCardID = this.service.IMSI.sim_id
                } break

                case 'ship': {
                    console.warn(this.service.IMSI)
                    values.IMSILocation = this.service.IMSI.location.location.LocationProviderIDX
                } break
            }

            for (const key in values) {
                if (MobileServiceValidator.isRuleExists(key)) {
                    if (MobileServiceValidator.isInvalid(key, values[key])) {
                        this.errors.fields[ key ] = MobileServiceValidator.getError()
                    }
                }
            }

            return !Object.keys(this.errors.fields).length
        },

        confirm() {
            if (this.validation()) {
                this.$emit('ready', { service: this.service })

                this.$emit('go-next')
            }
        },

        onChangeMSISDNAllocate() {
            this.errors.fields.MSISDN = null
            this.errors.fields.MSISDNProvider = null
            this.errors.fields.MSISDNNumber = null
        },

        onChangeMSISDNManual() {
            this.errors.fields.MSISDN = null

            this.$emit('ready', false)
        },

        onChangeIMSIAllocate() {
            this.errors.fields.IMSISimCardID = null
            this.errors.fields.IMSILocation = null
        },



        resetAvailable() {
            this.service.IMSI.location.available = null
            this.service.IMSI.location.variants = {
                list: null,
                checked: null,
            }
        },

        onChangePlace(address, prediction) {
            this.service.IMSI.location.address = address
            this.service.IMSI.location.prediction = prediction

            if (this.search_by_locationsvc) {
                this.onLocationSelected(prediction)
            }
        },

        checkAvailability() {
            if (this.validation('location')) {
                this.processing = true

                this.resetAvailable()

                if (this.search_by_locationsvc) {
                    const payload = {
                        addresstext: this.service.IMSI.location.address,
                    }

                    this.$store.dispatch('searchBroadbandLocation', payload).then(locations => {
                        this.service.IMSI.location.variants.list = locations

                        this.processing = false
                    }).catch(error => {
                        this.service.IMSI.location.available = false

                        this.processing = false
                    })
                } else {
                    /**
                     * @todo доработать, когда появитс АПИ для проверки доступности услуги найденной через Google Place
                     */
                    const address = this.service.IMSI.location.address

                    this.$store.dispatch('checkBroadbandAvailable', { address }).then(locations => {
                        if (locations.length > 1) {
                            this.service.IMSI.location.variants.list = locations
                        } else {
                            this.service.IMSI.location.available = true
                        }

                        this.processing = false
                    }).catch(error => {
                        this.service.IMSI.location.available = false

                        this.processing = false
                    })
                }
            }
        },

        onLocationSelected(location) {
            this.processing = true
            if (location.Formatted) {
                this.service.IMSI.location.address = location.Formatted
                this.service.IMSI.location.location = location
                this.service.IMSI.location.available = true
            }
            this.processing = false
        },

        onSIMIDChange() {
            const field = {
                rule: 'IMSISimCardID',
                value: this.service.IMSI.sim_id,
                message: 'Please enter the 16 or 19 digit SIM Card ID',
            }

            if (MobileServiceValidator.isInvalid(field.rule, field.value, field.message)) {
                this.errors.fields[field.rule] = MobileServiceValidator.getError()
            } else {
                this.$delete(this.errors.fields, field.rule)

                this.validateSIMCardID()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        ...mapGetters({
            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
        }),

        is_plan_selected() {
            console.log(this.service.plan)
            return (this.service && this.service.plan) ? true : false
        },

        simid_mask() {
            return {
                mask: '9999999999999999999',
                showMaskOnHover: false,
            }
        },
    },
}
</script>

<style lang="scss">
.stage-service {
    .app-error {
        margin-bottom: 24px;
    }

    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
            margin: 0;
        }

        &.allocate {
            display: block;

            h5 {
                font-size: 20px;
                line-height: 22px;
            }

            .allocate-options {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;

                .inputs {
                    margin-top: 24px;
                    flex-grow: 1;
                    max-width: 370px;

                    &>* {
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .app-radio-buttons {
                    min-width: 175px;
                    margin: 24px 25px 0 0;

                    .radio-btns {
                        label {
                            margin-bottom: 8px;

                            &:last-child { margin-bottom: 0; }
                        }
                    }
                }
            }
        }
    }

    form {
        .app-checkbox {
            margin-top: 16px;
        }
    }

    .app-input,
    .app-dropdown-select {
        max-width: 570px;
        margin-right: 30px;

        &:last-child {
            margin: 0;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-service {
        &>div {
            flex-direction: column;
            align-items: flex-start;
        }

        .app-input,
        .app-dropdown-select {
            max-width: none;
            margin: 0 0 24px;
        }
    }
}
</style>